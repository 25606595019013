import React from 'react';
import c from 'classnames';

import BannerImage from '../../static/img/banner.jpg';
import BannerMobileImage from '../../static/img/banner-mobile.jpg';
import LogoImage from '../../static/img/logo.svg';

import styles from './content.module.css';

export default function WalkInContent() {
  return (
    <section className={c(styles.splash)}>
      <img
        src={BannerImage}
        className="d-none d-md-block"
        style={{ width: '100%' }}
        alt="Easton Place Medical Centre"
      />
      <img
        src={BannerMobileImage}
        className="d-block d-md-none"
        style={{ width: '100%' }}
        alt="Easton Place Medical Centre"
      />
      <div className={c(styles.splashContent, 'py-4', 'py-md-5')}>
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-2">
              <img
                src={LogoImage}
                className="px-5 px-md-0"
                style={{ width: '100%' }}
                alt="Easton Place logo"
              />
            </div>
            <div className="col-12 col-md-10 pl-md-5">
              <p className="h3 mt-0">Pharmacy</p>
              <p>
                Easton Place Pharmacy is a locally owned independent drug store.
                It first opened in January 2020 and is proud to serve the
                Interlake area in Manitoba. Located within the Easton Place
                complex, the pharmacy is in an ideal position to work
                collaboratively with all the doctors, dentists, and other health
                professionals found within. Take advantage of the superb
                convenience with our full prescription service and essential
                over the counter offerings when you visit Easton Place!
              </p>
              <p>
                Ready for a change? Talk to us, and we can request a transfer of
                your prescription files on your behalf.
              </p>
              <p>
                We are also please to offer <strong>free delivery</strong> in Selkirk.
              </p>
              <p className="h5 mt-4">Contact</p>
              <p>
                Phone: <a href="tel:12044821980">204-482-1980</a>
                <br />
                Fax: <a href="tel:12044821996">204-482-1996</a>
                <br />
                Email:{' '}
                <a href="mailto:pharmacy@eastonplace.ca">
                  pharmacy@eastonplace.ca
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
