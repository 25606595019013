import React from 'react'
import Helmet from 'react-helmet';

import Layout from '../layout/layout'
import Content from '../pharmacy/content';

import '../../static/css/main.css';

function Pharmacy() {
  return (
    <Layout>
      <Helmet>
        <title>Pharmacy</title>
      </Helmet>
      <Content />
    </Layout>
  );
}

export default Pharmacy;
